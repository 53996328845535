































































import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {fileStoreActions, fileStoreGetter} from '@/stores/file.store';
import {Label} from '@/models/DbFile';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {mixins} from 'vue-class-component';


const FileStore = namespace('file');

@Component({
  components: {
    RJTextField,
    TableComponent: () => import('@/components/shared/table/Table.component.vue'),
  },
  mixins: [validationMixin],
  validations: {
    newLabel: {required},
  },
})

export default class LabelTableComponent extends mixins(ErrorMessageHandlerMixin) {

  @FileStore.Action(fileStoreActions.LOAD_LABELS_ACTION)
  private loadLabelsAction!: (customerId: string) => Promise<Label[]>;
  @FileStore.Action(fileStoreActions.DELETE_LABEL_ACTION)
  private deleteLabelAction!: (id: string) => Promise<void>;
  @FileStore.Action(fileStoreActions.CREATE_LABEL_ACTION)
  private createLabelAction!: (label: Label) => Promise<Label>;
  /**
   * string to search inside the table
   * @private
   */
  private searchString: string = '';
  private isLoading: boolean = false;
  private showCreateLabelDialog: boolean = false;
  private newLabel: string = '';

  @FileStore.Getter(fileStoreGetter.LABELS)
  private _labels!: Label[];

  private get labels() {
    return this._labels;
  }

  private get tableHeaders(): any {
    return [
      {text: this.$t('GENERAL.EMPLOYEE').toString(), value: 'name', width: '30%'},
      {text: this.$t('GENERAL.CREATED_AT').toString(), value: 'createdAt', width: '30%'},
      {text: this.$t('GENERAL.ACTIONS').toString(), value: 'actions', width: '0%'},
    ];
  }

  public async mounted() {
    this.isLoading = true;
    await this.loadLabelsAction(this.$route.params.companyId);
    this.isLoading = false;
  }

  private async deleteLabel(item: Label) {
    try {
      await this.deleteLabelAction(item.id!);
      this.$notifySuccessSimplified('MANAGE.LABEL_DELETE_MESSAGE.SUCCESS');
    } catch {
      this.$notifyErrorSimplified('MANAGE.LABEL_DELETE_MESSAGE.ERROR');
    } finally {
      await this.loadLabelsAction(this.$route.params.companyId);
    }
  }

  private async createLabel() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    } else {
      try {
        const name = this.newLabel.trim();
        if (name) {
          await this.createLabelAction({name, companyId: this.$route.params.companyId});
        }
        this.closeCreateDialog();
      } catch (e: any) {
        if (e.data.key === 'UNIQUE') {
          this.$notifyErrorSimplified('LABEL.NOTIFICATIONS.UNIQUE');
        } else {
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        }
      }
    }
  }

  private closeCreateDialog() {
    this.showCreateLabelDialog = false;
    this.$nextTick(() => {
        this.newLabel = '';
        this.$v.$reset();
    });
  }

}
